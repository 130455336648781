<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Reports</a></li>
    </ol>
    <h2 class="page-header">RM Summary</h2>
    <div class="panel panel-inverse">
      <div class="panel-body">
        <div class="row">
          <div class="form-group col-md-3 col-md-push-3">
            <label>Start Date</label>
            <date-picker v-model="start.date" format="yyyy-MM-dd" input-class="form-control bg-white"/>
          </div>
          <div class="form-group col-md-3">
            <label>End Date</label>
            <date-picker v-model="to.date" format="yyyy-MM-dd" input-class="form-control bg-white"/>
          </div>
          <div class="form-group col-md-3">
            <label>Branch</label>
            <v-select v-model="branch_id" v-validate="'required'" :options="branches" :reduce="c => c.id" label="name" name="branch" placeholder="Filter By Branch"/>
          </div>
        </div>
        <div class="row row-space-10 m-b-20">
          <div class="col-lg-3">
            <div class="widget widget-stats bg-gradient-teal m-b-10">
              <div class="stats-icon stats-icon-lg">
                <i class="fa fa-globe fa-fw"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Bid Bonds</div>
                <div class="stats-number">
                  {{ $number.format(bidbonds_count) }}
                </div>
                <div class="stats-progress progress">
                  <div class="progress-bar" style="width: 70.1%;"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="widget widget-stats bg-gradient-blue m-b-10">
              <div class="stats-icon stats-icon-lg">
                <i class="fa fa-dollar-sign fa-fw"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Bidbond Value</div>
                <div class="stats-number">
                  {{ $number.format(bidbonds_value) }}
                </div>
                <div class="stats-progress progress">
                  <div class="progress-bar" style="width: 40.5%;"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="widget widget-stats bg-gradient-purple m-b-10">
              <div class="stats-icon stats-icon-lg">
                <i class="fa fa-archive fa-fw"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Commission</div>
                <div class="stats-number">{{ $number.format(commission) }}</div>
                <div class="stats-progress progress">
                  <div class="progress-bar" style="width: 76.3%;"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="widget widget-stats bg-gradient-black m-b-10">
              <div class="stats-icon stats-icon-lg">
                <i class="fa fa-comment-alt fa-fw"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Companies</div>
                <div class="stats-number">
                  {{ $number.format(companies_count) }}
                </div>
                <div class="stats-progress progress">
                  <div class="progress-bar" style="width: 54.9%;"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <vue-good-table :columns="columns" :lineNumbers="true" :pagination-options="{ enabled: true, position: 'bottom' }" :rows="rm_summary" :search-options="{enabled: true,placeholder: 'Search this table'}">
          <div slot="table-actions">
            <download-excel v-if="rm_summary.length" :data="rm_summary" :export-fields="{
                    'Relationship Manager': 'name',
                    'Branch': 'branch_name',
                    'Companies' : 'companies_count',
                    'Bidbonds Count' : 'bidbonds_count',
                    'Bidbonds Value': 'bidbonds_value',
                    'Commission': 'commission'
                }"
                :title="'Bidbonds By RM'"
                class="btn btn-primary export-btn"
                name="bidbonds.xls"
            ><i class="fa fa-file-excel"></i> Export Excel
            </download-excel>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from 'vuejs-datepicker';
import moment from 'moment/src/moment';
import {mapGetters} from "vuex";
import helper from "@/mixins/helper";

export default {
  mixins: [helper],
  components: {
    DatePicker
  },
  data() {
    return {
      columns: [
        {
          label: "Relationship Manager",
          field: "name"
        },
        {
          label: "Companies",
          field: "companies_count",
          type: 'number'
        },
        {
          label: "Branch",
          field: "branch_name"
        },
        {
          label: "Bid Bonds",
          field: "bidbonds_count",
          type: 'number'
        },
        {
          label: "Bid Bond Value",
          field: "bidbonds_value",
          formatFn: this.formatFn
        },
        {
          label: "Commission",
          field: "commission",
          formatFn: this.formatFn
        }
      ],
      branch_id: null,
      start: {
        date: moment().add('week', '-1').toDate()
      },
      to: {
        date: moment().toDate()
      }
    }
  },
  computed: {
    ...mapGetters({
      rm_summary: 'getRMSummary',
      branches: 'getBranches'
    }),
    companies_count() {
      return this.rm_summary.length ? this.rm_summary.reduce((acc, item) => acc + parseInt(item.companies_count), 0) : 0
    },
    bidbonds_count() {
      return this.rm_summary.length ? this.rm_summary.reduce((acc, item) => acc + parseInt(item.bidbonds_count), 0) : 0
    },
    bidbonds_value() {
      return this.rm_summary.length ? this.rm_summary.reduce((acc, item) => acc + parseInt(item.bidbonds_value), 0) : 0
    },
    commission() {
      return this.rm_summary.length ? this.rm_summary.reduce((acc, item) => acc + parseInt(item.commission), 0) : 0
    },
    period() {
      return [this.start.date, this.to.date].join();
    }
  },
  watch: {
    branch_id() {
      this.fetch();
    },
    period() {
      if (this.start.date && this.to.date) {
        this.fetch();
      }
    }
  },
  mounted() {
    this.getRMSummary();
  },
  methods: {
    getRMSummary() {
      if (!this.rm_summary.length) {
        this.fetch();
      }
      if (!this.branches.length) {
        this.$store.dispatch("fetchBranches");
      }
    },
    fetch() {
      this.$store.dispatch('fetchRMSummary', {start: this.start.date, end: this.to.date, branch_id: this.branch_id});
    }
  }
}
</script>